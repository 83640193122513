<template>
  <div @click.stop.prevent="checkActive" :class="`datepicker_layout card ${roundedCard ? 'rounded-xl' : ''} bg-white justify-center content-center overflow-hidden`">
    <div class="flex  items-center justify-between text-lg relative font-bold p-1" v-if="!viewModals.yearpicker">
      <span @click="previousMonth"><i class="fas fa-chevron-left heading-5 cursor-pointer text-text2" ></i></span>
        <p class="text-center cursor-pointer" @click="togglingBetweenMonthAndDateView" v-if="!viewModals.yearpicker">
          <span v-if="viewModals.datepicker">
            {{displayDate | DatepickerDateFormat}}
          </span>
          <span v-if="viewModals.monthpicker">
            {{displayDate | DatepickerMonthFormat}}
          </span>
          <span class="ml-1" ><i class=" fas fa-chevron-down"></i></span>
        </p>
      <span @click="nextMonth"><i class="fas fa-chevron-right heading-5 cursor-pointer text-text2" ></i></span>
    </div>
    <div class="datepicker__divider"></div>
      <div :class="viewModals.yearpicker ? 'p-0' : 'py-0 px-3 datepicer_date__body'">
        <transition :name="transitionName" mode="out-in">
            <table v-if="viewModals.datepicker && datesList.length > 0">
            <thead>
              <td v-for="(data, index) in datepickerHeaders" :key="index">
                <p>{{data.title}}</p>
              </td>
            </thead>
            <tbody>
              <tr v-for="i in [0, 1, 2, 3, 4, 5, 6, 7]" :key="i">
                  <td v-for="(data, index) in datesList.slice(i * 7, (i * 7) + 7)" :key="index" @click="selectedDateHandler(data)" :style="columnNoForBackgroundColor > -1 ? index === columnNoForBackgroundColor ? `background-color:${backgroundColor === undefined ? '#f0f0f0' : backgroundColor}` : '' : ''" :class="data.disabled ? 'disabled_td': selectedValue === data.value ? 'bg-primary text-white hover_date_bg' : 'hover_date_bg'">
                      <p>{{data.text}}</p>
                      <div class="absolute flex bottom-px" v-if="getEventsForSpecifiedDate(data.value).length > 0">
                        <div class="datepicker_event__circle" :style="`background-color:#40E0D0`">
                        </div>
                        <p class="datepicker_event__count">{{getEventsForSpecifiedDate(data.value).length}}</p>
                      </div>
                  </td>
              </tr>
            </tbody>
          </table>
          <months v-if="viewModals.monthpicker" @selectedMonth="selectedMonthHandler" :monthsList="monthsList" :currentDate="referenceDate"/>
          <yearView v-if="viewModals.yearpicker" @yearSelected="selectedYearHandler" :currentDate="referenceDate" />
        </transition>
      </div>
  </div>
</template>
<script>
import moment from 'moment'
import months from './components/month'
import yearView from './components/year'
export default {
  components: {
    months,
    yearView
  },
  data () {
    return {
      transitionName: 'slide-fade',
      // Title of datepicker
      datepickerTitle: 'Jan 2019',
      // This date will be responsible to get data of dates in certain month and year for datepicker
      referenceDate: '',
      // Dates to display
      displayDate: '',
      // dates in date picker
      datesList: [],
      // selected date value
      selectedValue: '',
      // Reference for headers
      refDatepickerHeaders: [{title: 'Sun', val: 0}, {title: 'Mon', val: 1}, {title: 'Tue', val: 2}, {title: 'Wed', val: 3}, {title: 'Thu', val: 4}, {title: 'Fri', val: 5}, {title: 'Sat', val: 6}],
      datepickerHeaders: [],
      viewModals: {
        datepicker: true,
        monthpicker: false,
        yearpicker: false
      },
      monthsList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      columnNoForBackgroundColor: -1
    }
  },
  props: [ 'roundedCard', 'firstDayOfTheWeek', 'changeBackgroundOfDayInWeek', 'backgroundColor', 'events', 'value', 'min', 'max'],
  model: {
    prop: 'value',
    event: 'input'
  },
  beforeMount () {
    if (this.value !== '' && this.value !== null && this.value !== 'Invalid date') {
      this.displayDate = this.referenceDate
      this.selectedValue = moment(new Date(this.value + ' 00:00:00')).format('YYYY-MM-DD')
      this.referenceDate = moment(new Date(this.value + ' 00:00:00')).format('YYYY-MM-DD')
    } else {
      this.selectedValue = moment(new Date()).format('YYYY-MM-DD')
      this.referenceDate = moment(new Date()).format('YYYY-MM-DD')
      this.displayDate = this.referenceDate
      this.value = moment(new Date()).format('YYYY-MM-DD')
      this.$emit('input', this.value)
    }
  },
  watch: {
    referenceDate: {
      handler () {
        this.displayDate = this.referenceDate
        if (new Date(this.referenceDate).getMonth() !== new Date(this.value).getMonth() || new Date(this.referenceDate).getFullYear() !== new Date(this.value).getFullYear()) {
          this.selectedValue = ''
        } else {
          this.selectedValue = moment(new Date(this.value + ' 00:00:00')).format('YYYY-MM-DD')
        }
        this.getDatesForSelectedMonth()
      },
      deep: true
    },
    value: {
      handler () {
        this.setDatepickerHeaders()
        this.displayDate = this.referenceDate
        this.selectedValue = moment(new Date(this.value + ' 00:00:00')).format('YYYY-MM-DD')
        if (new Date(this.referenceDate).getMonth() !== new Date(this.value).getMonth()) {
          this.referenceDate = moment(new Date(this.value + ' 00:00:00')).format('YYYY-MM-DD')
        }
      },
      deep: true
    }
  },
  mounted () {
    this.setDatepickerHeaders()
  },
  methods: {
    checkActive () {
    },
    selectedDateHandler (data) {
      if (!data.disabled) {
        this.selectedValue = data.value
        this.value = moment(new Date(data.value + ' 00:00:00')).format('YYYY-MM-DD')
        this.displayDate = this.value
        this.$emit('input', this.value)
        this.$root.$emit('dateSelectionEvent', this.value)
      }
    },
    togglingBetweenMonthAndDateView () {
      if (this.viewModals.datepicker) {
        this.viewModals.datepicker = false
        this.viewModals.monthpicker = true
      } else if (this.viewModals.monthpicker) {
        this.viewModals.monthpicker = false
        this.viewModals.yearpicker = true
      }
      this.gettingDatepickerHeader()
    },
    getDatesForSelectedMonth () {
      // console.log(this.firstDayOfTheWeek)
      this.datesList.length = 0
      this.gettingDatepickerHeader()
      let startDay = this.getFirstDayOfMonth.getDay()
      let startingWeekDay = parseInt(this.firstDayOfTheWeek)
      let data = []
      if (startingWeekDay !== startDay) {
        for (let i = 0; i < this.datepickerHeaders.length; i++) {
          if (startDay !== this.datepickerHeaders[i].val) {
            let obj = {
              text: new Date(new Date(this.getFirstDayOfMonth).setDate(-i)).getDate(),
              value: moment(new Date(new Date(this.getFirstDayOfMonth).setDate(-i))).format('YYYY-MM-DD'),
              disabled: true
            }
            data.unshift(obj)
          } else {
            break
          }
        }
      }
      let x = data.length
      for (let i = 0; i < 42 - x; i++) {
        let val = new Date(new Date(this.getFirstDayOfMonth).setDate(i + 1))
        let obj = {
          text: val.getDate(),
          value: moment(val).format('YYYY-MM-DD'),
          disabled: this.getLastDayOfMonth.getDate() < i || this.checkForMaxDate(moment(val).format('YYYY-MM-DD')) || this.checkForMinDate(moment(val).format('YYYY-MM-DD'))
        }
        data.push(obj)
      }
      setTimeout(() => {
        this.datesList = data
      }, 200)
    },
    setDatepickerHeaders () {
      let startDay = parseInt(this.firstDayOfTheWeek)
      let splitFirst = this.refDatepickerHeaders.slice(startDay, this.refDatepickerHeaders.length)
      let spliceSecond = this.refDatepickerHeaders.slice(0, startDay)
      this.datepickerHeaders = splitFirst.concat(spliceSecond)
      if (this.changeBackgroundOfDayInWeek !== undefined && this.datepickerHeaders.length > 0) {
        this.columnNoForBackgroundColor = this.datepickerHeaders.findIndex(val => {
          return val.val === this.getStartDayForWeek(this.changeBackgroundOfDayInWeek)
        })
      }
    },
    getStartDayForWeek (value) {
      switch (value !== undefined ? value.toLowerCase() : value) {
        case 'monday':
          return 1
        case 'tuesday':
          return 2
        case 'wednesday':
          return 3
        case 'thursday':
          return 4
        case 'friday':
          return 5
        case 'saturday':
          return 6
        case 'sunday':
          return 0
        default:
          return 0
      }
    },
    nextMonth () {
      this.transitionName = 'slide-fade-right'
      if (this.viewModals.datepicker) {
        let x = ''
        if (this.getLastDayOfMonth.getDate() <= new Date(this.referenceDate).getDate()) {
          x = new Date(new Date(this.referenceDate).setDate(this.getLastDayOfMonth.getDate() - 2))
        } else {
          x = this.referenceDate
        }
        this.referenceDate = moment(new Date(new Date(x).setMonth(new Date(x).getMonth() + 1))).format('YYYY-MM-DD')
      } else {
        this.referenceDate = new Date(new Date(this.referenceDate).setFullYear(new Date(this.referenceDate).getFullYear() + 1))
      }
      this.gettingDatepickerHeader()
    },
    previousMonth () {
      this.transitionName = 'slide-fade'
      if (this.viewModals.datepicker) {
        this.referenceDate = moment(new Date(new Date(this.referenceDate).setMonth(new Date(this.referenceDate).getMonth() - 1))).format('YYYY-MM-DD')
      } else {
        this.referenceDate = new Date(new Date(this.referenceDate).setFullYear(new Date(this.referenceDate).getFullYear() - 1))
      }
      this.gettingDatepickerHeader()
    },
    selectedMonthHandler (data) {
      this.referenceDate = moment(new Date(new Date(this.referenceDate).setMonth(data))).format('YYYY-MM-DD')
      this.selectedValue = this.referenceDate
      this.viewModals.monthpicker = false
      this.viewModals.datepicker = true
    },
    selectedYearHandler (data) {
      if (data !== undefined) {
        this.referenceDate = moment(new Date(new Date().setFullYear(data))).format('YYYY-MM-DD')
      }
      this.viewModals.yearpicker = false
      this.viewModals.monthpicker = true
    },
    gettingDatepickerHeader () {
      let x = ''
      if (this.viewModals.datepicker) {
        x += this.monthsList[new Date(this.referenceDate).getMonth()] + ' '
      }
      x += new Date(this.referenceDate).getFullYear().toString()
      this.datepickerTitle = x
    },
    getEventsForSpecifiedDate (date) {
      return this.events === undefined ? [] : this.events.filter(val => {
        return val.date === date
      })
    },
    // check if date if greater then max date
    checkForMaxDate (date) {
      if (this.max === undefined) return false
      return new Date(date) > new Date(this.max)
    },
    checkForMinDate (date) {
      if (this.min === undefined) return false
      return new Date(date) < new Date(this.min)
    }
  },
  computed: {
    getFirstDayOfMonth () {
      return new Date(new Date(this.referenceDate).setDate(1))
    },
    getLastDayOfMonth () {
      return new Date(new Date(new Date(this.referenceDate).setMonth(new Date(this.referenceDate).getMonth() + 1)).setDate(0))
    },
    getDayFromDate () {
      return new Date(this.selectedValue).toUTCString().substr(0, 3)
    },
    getDateString () {
      let x = this.datepickerTitle.split(' ')
      return x[0] + ' ' + new Date(this.selectedValue).getDate() + ', ' + x[1]
    }
  }
}
</script>
<style scoped>
.datepicker_layout {
  font-family: 'Roboto-Regular' !important;
  max-width: 290px !important;
  width: 290px !important;
  height: 300px !important;
  box-shadow: 0 2px 4px 0 rgba(92, 91, 91, 0.2);
}
.datepicker__divider {
  border-bottom: 0.5px solid lightgray;
}
td {
  width: 35px !important;
  height: 35px;
  padding: 0px;
  margin: 0px 1px;
  overflow: hidden;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.datepicer_date__body{
  height: 250px;
}
tbody p {
  cursor: default;
}
thead {
  font-size: 12px;
  font-weight: bold;
  color: gray;
  margin: 0px;
  padding: 0px;
}
table{
  margin: 0px !important;
}
.disabled_td {
  color: grey;
}
.datepicker_event__circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0px 1px;
}
.datepicker_event__count{
  font-size: 8px;
  color: #40E0D0;
}
.hover_date_bg:hover{
    background-color: #E3F2FD;
}
.selected_date_bg {
    background-color: #0277BD;
}
</style>
